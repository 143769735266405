import React from "react";
import { useForm } from "react-hook-form";
import styles from "../style";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    axios
      .post("https://admin.tapu.com.iq/api/contact/createContact", {
        name: data.name,
        email: data.email,
        phoneNumber: data.company,
        subject: data.message,
      })
      .then((response) => {
        toast.success("تم ارسال الرسالة بنجاح");
        reset();
      })
      .catch((error) => console.error(error));
  };

  return (
    <div id="footer">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="rounded-lg w-full max-w-screen-2xl text-right"
      >
        <div className="text-right">
          <h1 className="flex-1 headingg text-white font-semibold ss:text-[36px] text-[32px]">
            تواصل معنا
          </h1>
          <p
            className={`${styles.paragraph} max-w-[470px] mb-5 text-right text-white`}
          >
            لمعلومات اكثر يرجى التواصل معنا من خلال ملء النموذج{" "}
          </p>
        </div>
        <div className="mb-4">
          <input
            {...register("name", { required: true })}
            className="w-full text-white px-3 py-2 text-right rounded-lg border border-[#F7F7F7] bg-inherit focus:outline-none focus:ring-2 focus:ring-white"
            type="text"
            placeholder="الأسم الكامل"
          />
          {errors.name && <span>This field is required</span>}
        </div>
        <div className="mb-4">
          <input
            {...register("email", { required: true })}
            className="w-full text-white px-3 py-2 text-right rounded-lg border border-[#F7F7F7] bg-inherit focus:outline-none focus:ring-2 focus:ring-white"
            type="email"
            placeholder="البريد الألكتروني"
          />
          {errors.email && <span>This field is required</span>}
        </div>
        <div className="mb-4">
          <input
            {...register("company", { required: true })}
            className="w-full text-white px-3 py-2 text-right rounded-lg border border-[#F7F7F7] bg-inherit focus:outline-none focus:ring-2 focus:ring-white"
            type="text"
            placeholder=" أسم الشركة"
          />
          {errors.company && <span>This field is required</span>}
        </div>
        <div className="mb-4">
          <textarea
            {...register("message", { required: true })}
            className="w-full text-white px-3 py-2 text-right rounded-lg border border-[#F7F7F7] bg-inherit focus:outline-none focus:ring-2 focus:ring-white"
            placeholder="رسالتك"
            rows="4"
          ></textarea>
          {errors.message && <span>This field is required</span>}
        </div>

        <button
          className="bg-white text-black px-4 py-2 w-32 font-bold rounded-full hover:bg-[#EBEBEB] hover:text-black focus:outline-none focus:ring-2 focus:ring-blue-500"
          type="submit"
        >
          ارسل الأن
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default ContactForm;
