import { useState } from "react";
import hamburger from "../assets/Hamburger.svg";
import close from "../assets/Close.png";
import logo from "../assets/logo.svg";
import { navLinks } from "../constants";
import { useNavigate } from 'react-router-dom';


const Navbar = () => {
  const [active, setActive] = useState("Home");
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();
  
  function handleClickNavMenuItem(id) {
    console.log("hello")
    const element = document.getElementById(id);
  if (element)
    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
      navigate(`/${id}`);
  }
  return (
    <nav className="w-full flex py-6 justify-between items-center navbar flex-row-reverse">
      <img src={logo} alt="hoobank" className="w-auto h-[48px]" />

      <ul className="list-none sm:flex hidden justify-between items-center flex-1 flex-row px-6">
        <li className="font-poppins font-normal cursor-pointer  text-[16px]">
          <button className="bg-[#000814] text-white py-3 px-6 rounded-[70px] hover:bg-white hover:text-[#000814]">
            {navLinks[navLinks.length - 1].title}
          </button>
        </li>
        <li className="flex flex-row-reverse space-x-4">
          {navLinks.slice(0, navLinks.length - 1).map((nav) => (
            <li
              key={nav.id}
              className={`font-poppins font-normal cursor-pointer text-[16px] ${
                active === nav.title
                  ? "bg-[#000814] text-white px-4 py-2 rounded-[60px]"
                  : "text-[#000814]"
              } hover:bg-[#000814] hover:text-white px-4 py-2 rounded-[60px]`}
              // onClick={() => setActive(nav.title)}
              onClick={() => handleClickNavMenuItem(nav.id)}
              style={{ margin: "1px 10px" }}
            >
              {nav.title}
              {/* <a href={`/#${nav.id}`}>{nav.title}</a> */}
            </li>
          ))}
        </li>
      </ul>

      <div className="sm:hidden flex flex-1 justify-end items-center flex-row-reverse">
        <img
          src={toggle ? close : hamburger}
          alt="menu"
          className="w-[28px] h-[28px] object-contain"
          onClick={() => setToggle(!toggle)}
        />

        <div
          className={`${
            !toggle ? "hidden" : "flex"
          } p-6 bg-white   absolute top-20 right-0  my-2 min-w-[140px] rounded-xl sidebar w-full`}
        >
         <ul className="list-none flex justify-end items-end flex-1  flex-col">
  {navLinks.map((nav, index) => (
    <li
      key={nav.id}
      className={`font-poppins font-medium cursor-pointer text-[16px] ${
        active === nav.title ? "text-white" : "text-dimWhite"
      } ${index === navLinks.length - 1 ? "mb-0" : "mb-4"}`}
      onClick={() => {
        setActive(nav.title);
        handleClickNavMenuItem(nav.id);
      }}
    >
      {index === navLinks.length - 1 ? (
        <button className="bg-[#000814] text-white py-2 px-4 rounded">
          {nav.title}
        </button>
      ) : (
        nav.title
      )}
    </li>
  ))}
</ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
