import styles from "../style";

const About = () => (
  <div id="about" className={` w-full   py-10 `}>
    <div
      className={`w-full flex items-end md:items-center justify-start sm:px-16 px-6 flex-col md:flex-row-reverse text-right sm:my-16 my-6 text-white`}
    >
<h2 className="font-Doran headingg hidden md:block font-semibold md:text-5xl text-3xl ss:leading-[100.8px] leading-[75px] w-[400px]">
        لمـَـحة<br/> عن الشركة
      </h2>
      <h2 className="font-Doran headingg sm:hidden font-semibold md:text-5xl text-3xl headingg  leading-[55px]  w-[400px]">
        لمـَـحة عن الشركة
      </h2>
      <div className="w-full md:mt-0 mt-6">
        <p className={`${styles.paragraph} text-right `}>
          شركة متخصصة بالتسويق وإدارة مبيعات المشاريع العقارية السكنية والتجا ر
          ية، تسعى للر يادة في السوق العراقية عبر تنظيم شراكات عمل مـــع أكبر
          المطورين والمســـتثمرين العقاريين معتمـــدة على فريق عمل محترف ذو خبرة
          عميقة في الســـوق المحلية والأســـواق الدولية الهامة مثل تركيا والشرق
          الأوسط، لتقدم لعملائها العراقيين أفضل المنتجات والخدمات العقارية.
        </p>
      </div>
    </div>
  </div>
);

export default About;
