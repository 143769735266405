import React from 'react'
import SwiperCore from "swiper"
import { Navigation, Pagination, A11y, Autoplay } from "swiper/modules"
import { Swiper, SwiperSlide, useSwiper } from "swiper/react"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"

SwiperCore.use([Navigation, Pagination])


function SingleSlider({data, handleCardClick, truncate}) {
  return (
     <Swiper
        modules={[Navigation, Pagination, A11y]}
        spaceBetween={0}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        autoHeight
        slidesPerView={data.length > 1 ? 1.2 : 1}
        breakpoints={{
          640: {
            slidesPerView: data.length > 1 ? 1.2 : 1,
            spaceBetween: 0,
          },
          768: {
            slidesPerView: data.length > 2 ? 2 : 1,
            spaceBetween: 0,
          },
          1024: {
            slidesPerView: data.length > 3 ? 3.7 : 1,
            spaceBetween: 0,
          },
        }}
     >
        {data.slice(-6).map((item, index) => (
            <SwiperSlide key={index}>
             <div className="flex flex-col p-4 rounded-[20px] my-5 hover:bg-gray-100 w-full sm:max-w-[400px]  cursor-pointer"
                onClick={() => handleCardClick(item._id)}
              >
                <img
                  src={item.image}
                  alt={`Card ${index + 1}`}
                  className="mx-auto rounded-[16px] w-full h-[230px] object-cover"
                />
                <div className="text-right" dir="rtl">
                  <h2 className="text-[18px] font-semibold my-2 font-Doran">
                    {item.title}
                  </h2>
                  <p className="text-gray-600">
                    {truncate(item.description, 20)}
                  </p>
                </div>
             </div>
          </SwiperSlide>
        ))}
      </Swiper>
  )
}

export default SingleSlider