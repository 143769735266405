import React from "react";
import styles from "../style";
import logowhite from "../assets/logoy.svg";
import bgfooter from "../assets/bg.svg";
import CTA from "../components/CTA";
import facebook from "../assets/facebook.svg";
import instagram from "../assets/instagram.svg";
import twitter from "../assets/twitter.svg";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { FaSnapchatGhost } from "react-icons/fa";

const Footer = () => (
  <section
    id="footer"
    className="flex flex-col  items-center justify-start bg-[#000814] h-[00px] rounded-t-[96px] bg-no-repeat bg-bottom	sm:px-16 px-6 sm:py-6 py-10 "
    style={{
      backgroundImage: `url(${bgfooter})`,
      backgroundSize: "cover",
      backgroundPosition: "start",
      backgroundRepeat: "no-repeat",
      width: "100%",
      height: "100%", // Adjust this height as needed
    }}
  >
    <div
      className={` md:flex  sm:py-16 py-6 mb-8 w-full flex-row-reverse items-start justify-end`}
    >
      <div className=" flex flex-col justify-center items-end gap-4">
        <img
          src={logowhite}
          alt="hoobank"
          className="w-auto h-[48px] object-contain "
        />
        <p className={`text-white mt-4 max-w-[312px] text-right`}>
          بغداد-المنصور-شارع الاميرات-مبنى رضا علوان-ط٦
        </p>
        <div className="text-white text-right flex flex-col gap-1">
          <p className={`${styles.heading3} headingg`}>معلومات التواصل</p>
          <p className={styles.paragraph2}>info@tabu.com</p>
          <p className={styles.paragraph2}>+964 784 445 2288</p>
          <p className={styles.paragraph2}>+964 774 445 2288 </p>
        </div>
        <div className="flex md:mt-24 mt-6 flex-col gap-4 justify-start items-end">
          <h1 className="text-white">:تواصل معنا على</h1>
          <div className="flex gap-4">
            <a
              href="https://x.com/Tapu_com_iq"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-[#FFCC00] p-1 rounded-lg hover:bg-[#000814] transition-all duration-300 transform hover:scale-110"
            >
              <FaXTwitter size={24} color="white" />
            </a>
            <a
              href="https://web.facebook.com/tapuiraqi"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-[#FFCC00] p-1 rounded-lg hover:bg-[#000814] transition-all duration-300 transform hover:scale-110"
            >
              <FaFacebook size={24} color="white" />
            </a>
            <a
              href="https://www.instagram.com/tapu.com.iq/"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-[#FFCC00] p-1 rounded-lg hover:bg-[#000814] transition-all duration-300 transform hover:scale-110"
            >
              <FaInstagram size={24} color="white" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCsAoOaP-YVJ4QvZbKhkT5Mw"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-[#FFCC00] p-1 rounded-lg hover:bg-[#000814] transition-all duration-300 transform hover:scale-110"
            >
              <FaYoutube size={24} color="white" />
            </a>
            <a
              href="https://www.tiktok.com/@tapu.com.iq?is_from_webapp=1&sender_device=pc"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-[#FFCC00] p-1 rounded-lg hover:bg-[#000814] transition-all duration-300 transform hover:scale-110"
            >
              <FaTiktok size={24} color="white" />
            </a>
            <a
              href="https://www.linkedin.com/company/tapuiraq"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-[#FFCC00] p-1 rounded-lg hover:bg-[#000814] transition-all duration-300 transform hover:scale-110"
            >
              <FaLinkedin size={24} color="white" />
            </a>
            <a
              href="https://www.snapchat.com/add/tapucom.iq"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-[#FFCC00] p-1 rounded-lg hover:bg-[#000814] transition-all duration-300 transform hover:scale-110"
            >
              <FaSnapchatGhost size={24} color="white" />
            </a>
          </div>
        </div>
      </div>
      <div className="flex-[1.5] w-full flex flex-row justify-between flex-wrap md:mt-0 mt-10">
        <div>
          <CTA />
        </div>
      </div>
    </div>
    <div className="w-full flex justify-center items-center md:flex-row flex-col pt-6 border-t-[1px] border-t-[#3F3E45]">
      <p className=" text-center font-Brando text-[16px] leading-[27px] text-white">
        © 2024 tabu. All rights reserved
      </p>
    </div>
  </section>
);

export default Footer;
