import styles from "../style";
import Rectangle from '../assets/Rectangle.png';

const Banner = () => (
  <section id="product" className={styles.paddingY}>
    <div className=" flex flex-col md:flex-row-reverse rounded-[32px] z-0">
      <img
        src={Rectangle}
        alt="billing"
        className="w-full md:w-2/3 relative z-[5] rounded-[32px]  "
      />
      <div className="text-black w-full md:w-1/2 flex justify-end items-center py-10 md:py-24 md:px-10">
        <div className="flex flex-col gap-5">
          <h1 className="flex-1 font-Doran font-bold sm:text-5xl text-3xl headingg md:leading-[70.8px] leading-[45px] text-right">
         لطباعة والنشر. يستخدم كاتب الروماني شيشرون يعود 
          </h1>
          <p className="font-medium text-lg text-right text-black ">
          "لوريم ايبسوم" هو نص افتراضي يستخدم في صناعة الطباعة والنشر. يستخدم لوريم ايبسوم كنص تعبيري لاستبدال المحتوى الفعلي في تصميمات الصفحات لتوفير فكرة عن كيفية ظهور النص النهائي دون الحاجة إلى استخدام محتوى حقيقي. النص يأتي من عمل كلاسيكي للكاتب الروماني شيشرون يعود إلى عام 45 قبل الميلاد.
          </p>
        
        </div>
      </div>
    </div>
  </section>
);

export default Banner;
