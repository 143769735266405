import React, { useState } from 'react';
import { Link } from 'react-scroll';
import close from "../assets/close.svg";
import logowhite from "../assets/logoy.svg";
import { navLinks } from "../constants";
import menu from "../assets/menu.svg";

const handleClickNavMenuItem = (id, setToggle) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: "smooth",
      block: "start", // Adjust to 'start' for better UX
    });
    setToggle(false); // Close the menu after clicking a link
  }
};

const Navbar = () => {
  const [active, setActive] = useState("Home");
  const [toggle, setToggle] = useState(false);

  return (
    <nav className="w-full flex py-6 justify-between items-center navbar flex-row-reverse " aria-label="Main Navigation">
      <img src={logowhite} alt="tapu" className="w-auto h-[48px]" />

      <ul className="list-none sm:flex hidden justify-between items-center flex-1 flex-row px-6">
        <li className="font-poppins font-normal cursor-pointer text-[16px]">
          <button 
            className="bg-white text-[#000814] py-3 px-6 rounded-full shadow-md transition-all duration-200 ease-in-out transform hover:bg-[#000814] hover:text-white hover:scale-105"
          >
            {navLinks[navLinks.length - 1].title}
          </button>
        </li>
        <div className="flex flex-row-reverse space-x-4">
          {navLinks.slice(0, navLinks.length - 1).map((nav) => (
            <Link
              key={nav.id}
              to={nav.id}
              smooth={true}
              duration={500}
              className={`font-poppins font-normal cursor-pointer text-[16px] ${
                active === nav.title
                  ? "bg-white text-[#000814] px-4 py-2 rounded-full"
                  : "text-white"
              } hover:bg-white hover:text-[#000814] px-4 py-2 rounded-full shadow-md transition-all duration-200 ease-in-out transform hover:scale-105`}
              onClick={() => {
                setActive(nav.title);
                setToggle(false);
              }}
              style={{ margin: "1px 10px" }}
            >
              {nav.title}
            </Link>
          ))}
        </div>
      </ul>

      <div className="sm:hidden flex flex-1 justify-end items-center flex-row-reverse">
        <img
          src={toggle ? close : menu}
          alt="menu"
          className="w-[28px] h-[28px] object-contain"
          onClick={() => setToggle(!toggle)}
        />

        <div
          className={`${!toggle ? "hidden" : "flex"} p-6 bg-white absolute top-20 right-0 my-2 min-w-[140px] rounded-xl sidebar w-full transition-all duration-200 ease-in-out`}
        >
          <ul className="list-none flex justify-end items-end flex-1 flex-col" role="menu">
            {navLinks.map((nav, index) => (
              <li
                key={nav.id}
                className={`font-poppins font-medium cursor-pointer text-[16px] ${
                  active === nav.title ? "text-[#000814]" : "text-gray-600"
                } ${index === navLinks.length - 1 ? "mb-0" : "mb-4"}`}
                onClick={() => {
                  handleClickNavMenuItem(nav.id, setToggle);
                  setActive(nav.title);
                }}
              >
                {index === navLinks.length - 1 ? (
                  <button className="bg-[#000814] text-white py-2 px-4 rounded">
                    {nav.title}
                  </button>
                ) : (
                  <Link to={nav.id} smooth={true} duration={500}>
                    {nav.title}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
