import React from "react";
import styles from "../style";
import  Footer from "../components/Footer";
import  Navbar from "../components/Navbar2";
import axios from "axios";
import { useQuery } from "react-query";

const fetchNewsById = async (id) => {
  const { data } = await axios.get(`https://admin.tapu.com.iq/api/news/getNewsById/${id}`);
  return data;
};

function SubBlog() {
  const id = window.location.pathname.split("/")[2];
  const { data = {}, isError } = useQuery(['news', id], () => fetchNewsById(id));

  if (isError) return 'An error has occurred';


  return (
    <div className="w-full overflow-hidden">
      <div className={`${styles.paddingX} `}>
        <div className={`${styles.boxWidth}`}>
          <Navbar />
        </div>

        <div className="mx-2 md:mx-32 my-10">
          <div className="items-center justify-center mx-auto max-w-screen-lg  ">
            <div className=" gap-5 mt-4">
              <div className=" mx-auto h-auto md:h-[40rem] w-full rounded-[24px] overflow-hidden">
                <img
                  className="h-full w-full object-cover"
                  src={data.image}
                  alt=""
                />
              </div>
            </div>
            <div className=" w-full md:w-3/4 text-right mx-auto">
              <div className="">
                <h2
                  className={`headingg font-semibold xs:text-[36px] text-[24px] mt-6  text-right`}
                >
                  {data.title}
                </h2>
                <p className=" text-[18px] my-6">
                  {data.description}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default SubBlog;
