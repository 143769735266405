import React from 'react';

const NewsServce = ({ content, title, img }) => (
<div className='flex items-center'>
  <div className="flex flex-row-reverse items-center w-[400px] md:my-4 md:mx-10 my-4 sm:h-[100px] gap-4 px-4 mx-auto bg-white rounded-lg transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl">
    <div className="flex justify-center items-center rounded-full">
      <img
        src={img}
        alt={title}
        className="w-[50px] sm:w-full sm:h-[100px] object-contain mx-auto"
      />
    </div>
    <p className="text-lg sm:text-[20px] font-medium text-black text-right w-full">
      {content}
    </p>
  </div>
</div>

);

export default NewsServce;
