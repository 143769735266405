import React from "react";
import { feedback } from "../constants";
import styles from "../style";
import CardServace from "./CardServace";

const Service = () => (
  <section
    id="service"
    className={` ${styles.flexCenter} flex-col relative mx-auto`}
  >
  <div className={`w-full flex items-end md:items-start justify-start ${styles.paddingX} flex-col  mb-6  md:flex-row-reverse text-right sm:mb-10 mt-16  `}>
  <h2 className="headingg font-semibold md:text-5xl text-3xl  ss:leading-[100.8px] leading-[75px] md:mt-6 w-[400px]">
        خدمـات الـشركـــــــة
      </h2>
      <div className="w-full md:mt-0 mt-6">
        <div className="flex flex-wrap justify-end w-full ">
          {feedback.map((card) => (
            <CardServace key={card.id} {...card} />
          ))}
        </div>
      </div>
    </div>
  </section>
);

export default Service;
