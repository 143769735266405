import React, { useEffect } from 'react';
import Navbar from '../components/Navbar';
import styles from "../style";
import Rectangle from '../assets/Hero.png';
import { useParams } from 'react-router-dom';

const Hero = () => {
  const { id } = useParams();
  
   useEffect(() => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  }, [id]);

  return (
    <section
      id="Hero"
      className="flex flex-col  bg-cover bg-center bg-no-repeat relative"
      style={{ backgroundImage: `url(${Rectangle})` }}>
      <div className="sm:mx-16 mx-6">
        <Navbar />
      </div>

     
      <div className={`flex-1 ${styles.flexStart} flex-col xl:px-0  text-right sm:mx-16 mx-6 sm:py-28 h-full py-6`}>
        <div className="flex flex-row items-center mb-4 py-[6px] px-4 bg-gradient-to-r from-gray-100 to-[#EBEBEB] rounded-[24px] ">
          <p className={`${styles.paragraph} ml-2 text-black`}>
            لماذا طابو العقارية ؟
          </p>
        </div>
    
        <div className="flex flex-row justify-between items-center w-full text-white">
          <h1 className="flex-1 font-Doran font-semibold md:text-5xl text-3xl headingg md:leading-[80.8px] leading-[55px] text-white">
            تقديـــم أفضل
            <br className="" />
            <span className="headingg"> المنتجات والخـدمات</span>
          </h1>
        </div>
    
        <h1 className="flex-1 font-Doran font-semibold md:text-5xl text-3xl headingg md:leading-[80.8px] leading-[55px] text-white">
          العقارية للعملاء العراقيين
        </h1>
        <p className={`${styles.paragraph} max-w-[470px] mt-5 text-right text-white`}>
          من خلال تنظيم شراكات عمل مع أكبر المطورين والمســـتثمرين العقاريـــين
          معتمـــدة على فريق عمل محـــترف ذو خبرة عميقة في الســـوق المحلية
          والأسواق الدولية الهامة مثل تركيا والشرق الأ وسط
        </p>
      </div>
    </section>
  );
};

export default Hero;
