import React from 'react'
import Navbar from '../components/Navbar2'
import News from '../components/News'

function Cards() {
  return (
   <section
      id="Hero"
      className="flex flex-col  bg-cover bg-center bg-no-repeat relative">
      <div className="sm:mx-16 mx-6">
        <Navbar />
      </div>
        <News isFormCard={true}/>
      </section>
  )
}

export default Cards