import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../style';
import axios from 'axios';
import { useQuery } from 'react-query';
import { Swiper, SwiperSlide } from 'swiper/react';
import SingleSlider from './SingleSlider';

const fetchNews = async () => {
  const { data } = await axios.get(
    'https://admin.tapu.com.iq/api/news/getAllNews'
  );
  return data;
};

function truncate(str, no_words) {
  return str.split(' ').splice(0, no_words).join(' ');
}

const News = ({ isFormCard }) => {
  const navigate = useNavigate();
  const path = window.location.pathname;
  const { data = [], isLoading, isError } = useQuery('news', fetchNews);

  const handleCardClick = (id) => {
    window.scrollTo(0, 0);
    navigate(`/SubBlog/${id}`);
  };

  if (isLoading) return 'Loading...';
  if (isError) return 'An error has occurred';

  return (
    <section className={styles.paddingY}>
      <div
        id="new"
        className="flex xl:flex-row flex-wrap justify-center items-center gap-4 relative"
      >
        {path !== '/cards' ? (
          <button
            onClick={() => navigate('/cards')}
            className="absolute -top-8 sm:left-16 bg-black text-white px-6 py-2 rounded-[24px] hover:bg-gray-500  transition duration-500 ease-in-out"
          >
            عرض المزيد
          </button>
        ) : null}
        {isFormCard ? (
          data.map((item, index) => (
            <div
              key={index}
              className="flex flex-col p-4 rounded-[20px] my-5 hover:bg-gray-100 w-full sm:max-w-[400px] cursor-pointer"
              onClick={() => handleCardClick(item._id)}
            >
              <img
                src={item.image}
                alt={`Card ${index + 1}`}
                className="mx-auto rounded-[16px] w-full h-[230px] object-cover"
              />
              <div className="text-right" dir="rtl">
                <h2 className="text-[20px] font-semibold my-2 font-Doran">
                  {item.title}
                </h2>
                <p className="text-gray-600 overflow-hidden overflow-ellipsis">
                  {truncate(item.description, 20)}
                </p>
              </div>
            </div>
          ))
        ) : (
          <SingleSlider data={data} handleCardClick={handleCardClick} truncate={truncate}/>
        )}
      </div>
    </section>
  );
};

export default News;
