import React from 'react';
import About from "../components/About";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import News from "../components/News";
import Service from "../components/Service";
import styles from "../style";

const App = () => (
  <div className="w-full overflow-hidden">
    <div id="hero">
      <Hero />
    </div>
    
    <div className="bg-white">
      <div id="about" className="bg-[#000814]">
        <About />
      </div>
      <div id="banner" className={`${styles.paddingX}`}>
        <Banner />
      </div>
      <div id="service">
        <Service />
      </div>
      <div id="news" className={`${styles.paddingX}`}>
        <News />
      </div>
      <div id="footer">
        <Footer />
      </div>
    </div>
  </div>
);


export default App;
