import service from "../assets/Group1.svg";
import service1 from "../assets/Group2.svg";
import service2 from "../assets/Group3.svg";
import service3 from "../assets/Group4.svg";
import service4 from "../assets/Group5.svg";

export const navLinks = [
  {
    id: "Hero",
    title: "الرئيسية",
  },
  {
    id: "about",
    title: "من نحن",
  },
  {
    id: "service",
    title: "الخدمات",
  },
  {
    id: "new",
    title: "الاخبار",
  },
  {
    id: "footer",
    title: "تواصل معنا",
  },
];

export const feedback = [
  {
    id: "feedback-1",
    content: "تصـــميم وتـنفيذ حــــــملات واسـتراتيجيات التـسويق العقاري",
    name: "Herman Jensen",
    title: "Founder & Leader",
    img: service,
  },
  {
    id: "feedback-2",
    content: "إدارة عـمليات مـــــــبيعات المجمعات السكنية",
    name: "Steve Mark",
    title: "Founder & Leader",
    img: service1,
  },
  {
    id: "feedback-3",
    content: "تطــــــــوير الأعــــــــمال والــحلول في السوق العقارية      ",
    name: "Kenn Gallagher",
    title: "Founder & Leader",
    img: service2,
  },
  {
    id: "feedback-4",
    content: "تقـــديم الاســــــــتشارات الخاصة بالاستثمار العقاري      ",
    name: "Kenn Gallagher",
    title: "Founder & Leader",
    img: service3,
  },
  {
    id: "feedback-5",
    content: "تقديم الاســــــــتشارات والحلول في مجال التطوير العقاري",
    name: "Kenn Gallagher",
    title: "Founder & Leader",
    img: service4,
  },
];

export const footerLinks = [
  {
    title: "Useful Links",
    links: [
      {
        name: "Content",
        link: "https://www.hoobank.com/content/",
      },
      {
        name: "How it Works",
        link: "https://www.hoobank.com/how-it-works/",
      },
      {
        name: "Create",
        link: "https://www.hoobank.com/create/",
      },
      {
        name: "Explore",
        link: "https://www.hoobank.com/explore/",
      },
      {
        name: "Terms & Services",
        link: "https://www.hoobank.com/terms-and-services/",
      },
    ],
  },
];
